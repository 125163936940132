'use strict'

angular
	.module('cb.prospect', [])
	.controller('ProspectListController', ["$scope", "$routeParams", "ProspectService", "$location", "$modal", function ($scope, $routeParams, ProspectService, $location, $modal) {


		//Function to get all prospect by batchid
		$scope.getProspectList = function () {

			$scope.GetProspectPromise = ProspectService.getProspectsByBatchId($routeParams.id);
			$scope.GetProspectPromise.then(function (output) {
				$scope.prospects = output.data.Results;
				$scope.hasDuplication = $scope.prospects.some(a => a.PossDup);
			}, function (error) {
				$scope.fetchedDataError = true;
				NotificationService.alert('Prospect List Error', 'There was an error retriving the prospect for this batch.', 'error', {});
			});
		};
		$scope.DeduplicationBatch = function () {
			$scope.openModal('ProspectDeduplicationController', 'ProspectDeduplication.html', $scope.prospects.filter(p => p.PossDup == true));
		};
		$scope.openQuickView = function (Id) {
			$scope.openModal('ProspectQuickViewController', 'views/modal-templates/prospect-quick-view.html', Id)
		};
		$scope.openModal = function (controller, template, data) {
			var modalInstance = $modal.open({
				animation: true,
				size: 'lg',
				templateUrl: template,
				windowClass: 'z-index: 9999; ',
				controller: controller,
				resolve: {
					Data: function () {
						return data;
					}
				}
			});
			modalInstance.result.finally(function () {
				$scope.getProspectList();
			});
		};
	}])
	.controller('ProspectDuplicatesListController', ["$scope", "$routeParams", "ProspectService", "$modal", "$location", function ($scope, $routeParams, ProspectService, $modal, $location) {
			$scope.init = function () {
				$scope.GetDuplicates();
			};

			$scope.GetDuplicates = function () {
				$scope.GetDuplicatesPromise = ProspectService.getDuplicates($routeParams.id);
				$scope.GetDuplicatesPromise.then(function (output) {
					$scope.Duplicates = output.data.Prospects;
				}, function (error) {
					$scope.fetchedDataError = true;
					NotificationService.alert('Duplicates List Error', 'There was an error retriving the Duplicates prospect for this batch.', 'error', {});
				});
			};

			$scope.openDeduplication = function (Id) {
				$scope.openModal('ProspectDeduplicationController', 'ProspectDeduplication.html', Id)
			};

			$scope.back = function () {
				$location.path('prospect-list/' + $routeParams.id)
			};

			$scope.openModal = function (controller, template, data) {
				var modalInstance = $modal.open({
					animation: true,
					size: 'lg',
					templateUrl: template,
					controller: controller,
					resolve: {
						Data: function () {
							return data;
						}
					}
				});
				modalInstance.result.then(function (output) {
					if (output.Moveto == 'Next') {
						var Currentindex = $scope.Duplicates.findIndex(a => a.Id == output.CurrentId);
						$scope.next = ($scope.Duplicates[Currentindex + 1] ? $scope.Duplicates[Currentindex + 1].Id : null);

					} else if (output.Moveto == 'Back') {
						var Currentindex = $scope.Duplicates.findIndex(a => a.Id == output.CurrentId);
						$scope.next = ($scope.Duplicates[Currentindex - 1] ? $scope.Duplicates[Currentindex - 1].Id : null);
					}
				})
				modalInstance.result.finally(function () {
					if ($scope.next != null) {
						$scope.openDeduplication($scope.next)
						$scope.next = null;
					}
					$scope.init();
				});
			};
		}
	])

	.controller('ProspectDeduplicationController', ["$scope", "Data", "ProspectService", "$modalInstance", "$modal", "NotificationService", function ($scope, Data, ProspectService, $modalInstance, $modal, NotificationService) {
		$scope.init = function () {
			$scope.DisplayingDetails = false;
			$scope.another = { n: true };
			$scope.dataloaded = false;

			$scope.dataIndex = 0;
			$scope.dataLength = Data.length;

			$scope.getDeDuplicationData(Data[$scope.dataIndex].Id);
		};

		$scope.getDeDuplicationData = function (id) {
			$scope.GetDuplicatesPromise = ProspectService.GetDuplicatesofprospect(id);
			$scope.GetDuplicatesPromise.then(function (output) {
				$scope.dataloaded = true;
				$scope.Prospect = output.data.Prospect;
			}, function (error) {
				NotificationService.alert('Duplicates List Error', 'There was an error retriving the Duplicates prospect for this batch.', 'error', {});
			});
		};

		$scope.UpdateProspect = function () {
			var data = {
				ProspectDetail: $scope.Prospect
			}
			$scope.updateProspectPromise = ProspectService.saveDuplicate(data);
			$scope.updateProspectPromise.then(function (output) {
				NotificationService.alert('Prospect Saved', 'Prospect has been updated.', 'success', {});
			}, function (error) {
				NotificationService.alert('Prospect Error', 'There was an error updating this prospect.', 'error', {});
			});
		}

		$scope.isDuplication = function () {
			//if (confirm("Are you sure it's a duplication?")) {
				$scope.isDuplicationPromise = ProspectService.SetAsADuplication(Data[$scope.dataIndex].Id);
				$scope.isDuplicationPromise.then(function (output) {
					if (output.data.ProspectId != 0) {
						NotificationService.alert('Error', 'There was an error changeing prospect Duplication.', 'error', {});

					} else {
						NotificationService.alert('Update Successful', ""), 'success';
						if ($scope.another.n) {
							//$scope.removeDuplicateItem(Data[$scope.dataIndex].Id);
							$scope.Next();
						} else {
							$scope.close();
						}
					}
				}, function (error) {
					console.error(error);
					NotificationService.alert('Error', 'There was an error changeing prospect Duplication.', 'error', {});

				})
			//}
		};

		$scope.isNotDuplication = function () {
			//if (confirm("Are you sure it's not a duplication?")) {
				$scope.isNotDuplicationPromise = ProspectService.SetAsNonDuplication(Data[$scope.dataIndex].Id);
				$scope.isNotDuplicationPromise.then(function (output) {
					if (output.data.ProspectId == 0) {
						NotificationService.alert('Error', 'There was an error changeing prospect Duplication.', 'error', {});

					} else {
						NotificationService.alert('Update Successful', ""), 'success';
						if ($scope.another.n) {
							//$scope.removeDuplicateItem(Data[$scope.dataIndex].Id);
							$scope.Next();
						} else {
							$scope.close();
						}
					}
				}, function (error) {
					console.error(error);
					NotificationService.alert('Error', 'There was an error changeing prospect Duplication.', 'error', {});

				})
			//}
		};

		$scope.removeDuplicateItem = function (id) {
			Data = Data.filter(item => item.Id != id);
			$scope.dataLength = Data.length;
			$scope.dataIndex--;
		};

		$scope.Next = function () {
			$scope.dataIndex++;
			$scope.getDeDuplicationData(Data[$scope.dataIndex].Id);
		};

		$scope.Back = function () {
			if ($scope.dataIndex > 0) {
				$scope.dataIndex--;
				$scope.getDeDuplicationData(Data[$scope.dataIndex].Id);
			};
		};
		$scope.openQuickView = function (id, type) {
			$scope.DisplayingDetails = true;
			$scope.openModal('DeDuplicateQuickViewController', 'views/modal-templates/deduplicate-quick-view.html', {Id: id, Type: type});
		};
		$scope.openModal = function (controller, template, data) {
			var modalInstance = $modal.open({
				animation: true,
				size: 'lg',
				templateUrl: template,
				windowClass: 'z-index: 9999',
				controller: controller,
				resolve: {
					Data: function () {
						return data;
					}
				}
			});
			modalInstance.result.finally(function () {
				$scope.DisplayingDetails = false;
				$scope.getProspectList();
			});
		};
		$scope.close = function () {
			$modalInstance.dismiss();
		};
	}])

	.controller('ProspectDetailsController', ["$scope", "$routeParams", "ProspectService", "PickListService", "NotificationService", "ParamService", "LeadProviderService", "$location", function ($scope, $routeParams, ProspectService, PickListService, NotificationService, ParamService, LeadProviderService, $location) {
		$scope.Today = new Date();
		$scope.openPopups = {};
		$scope.open = function ($event, variable) {
			$event.preventDefault();
			$event.stopPropagation();

			for (var k in $scope.openPopups) {
				$scope.openPopups[k] = false;
			};
			$scope.openPopups[variable] = true;
		};

		$scope.init = function () {
			$scope.getParams();
			$scope.getMailingStatus();
			$scope.prospect = {};
			$scope.originalProspect = {};
			if ($routeParams.id > 0) {
				$scope.viewProspect();
			}
			else
			{
				$scope.prospect.BatchId = 0;
				$scope.originalProspect.BatchId = 0;
				$scope.getConservatoryType();
			}

			$scope.GetAllLeadProviderPromise = LeadProviderService.getAllLeadProviders();
			$scope.GetAllLeadProviderPromise.then(function (output) {
				$scope.leadProviderList = output.data.LeadProviders;
			}, function (error) {
				NotificationService.alert('Lead Provider Error', 'There was an error gathering all the data, please try again.', 'error', {});
			});
			
		};

		$scope.$on('$locationChangeStart', function (event) {
			if (angular.equals($scope.prospect, $scope.originalProspect)) {
			}
			else {
				var answer = confirm("Unsaved data will be lost, are you sure you want to leave this page?")
				if (!answer) {
					event.preventDefault();
				}
			}
		});

		window.onbeforeunload = function (event) {
			if (angular.equals($scope.prospect, $scope.originalProspect)) { }
			else {
				var message = 'Unsaved data will be lost.Are you sure you want to close the page ?';
				if (typeof event == 'undefined') {
					event = window.event;
				}
				if (event) {
					event.returnValue = message;
				}
				return message;
			}
		}

		//Function to get prospect by id
		$scope.viewProspect = function () {

			$scope.GetProspectPromise = ProspectService.getProspectDetail($routeParams.id);
			$scope.GetProspectPromise.then(function (output) {
				$scope.prospect = output.data.ProspectDetail;
				$scope.getConservatoryType();
				angular.copy($scope.prospect, $scope.originalProspect);

			}, function (error) {
				$scope.fetchedDataError = true;
				NotificationService.alert('Prospect Error', 'There was an error retriving this prospect', 'error', {});
			});
		};
		$scope.getConservatoryType = function () {
			var ldate = "0001-01-01";
			$scope.GetConservatoryTypePromise = ProspectService.GetConservatoryType(ldate, $scope.prospect.BatchId,0);
			$scope.GetConservatoryTypePromise.then(function (output) {
				$scope.ConservatoryTypes = output.data.Picklists

			}, function (error) {
				$scope.fetchedDataError = true;
				NotificationService.alert('Conservatory Types List Error', 'There was an error retriving the conservatory types.', 'error', {});
			});
		};
		$scope.getParams = function () {
			$scope.gettingParamsPromise = PickListService.getPickListByPickListName(['Title', 'Status','LeadSource', 'ddmSiteType']);
			$scope.gettingParamsPromise.then(function (output) {
				$scope.Picks = output.data.PickListEntries;
			}, function (error) {
				NotificationService.alert('Parameters Error', 'There was an error getting the parameters data.', 'error', {});
			});
		};

		$scope.getMailingStatus = function () {
			$scope.gettingMailingStatusesPromise = ParamService.getParamByEntityAndField('Prospect', 'MailingStatus');
			$scope.gettingMailingStatusesPromise.then(output => {
				$scope.mailingStatuses = output.data.Params;
			}, err => {
				NotificationService.alert('Statuses Error', 'There was an error getting the mailing statuses.', 'error', {});
			});
		};

		$scope.save = function () {
			$scope.prospectCopy = {};
			angular.copy($scope.prospect, $scope.prospectCopy);
			var data = {
				ProspectDetail: $scope.prospectCopy
			};

			//handle BST dates
			data.ProspectDetail.CheckTPS = window.formatDate(data.ProspectDetail.CheckTPS);
			data.ProspectDetail.FirstMailed = window.formatDate(data.ProspectDetail.FirstMailed);
			data.ProspectDetail.FollowUpMailed = window.formatDate(data.ProspectDetail.FollowUpMailed);
			data.ProspectDetail.PlanApplDate = window.formatDate(data.ProspectDetail.PlanApplDate);
			data.ProspectDetail.ManualMailedDate = window.formatDate(data.ProspectDetail.ManualMailedDate);

			angular.copy($scope.orderDetails, $scope.originalOrderDetails);
			$scope.savingProspectPromise = ProspectService.saveProspect(data);
			$scope.savingProspectPromise.then(function (output) {
				NotificationService.alert('Save Success', 'This prospect was saved successfully.', 'success', {});
			}, function (error) {
				NotificationService.alert('Prospect Error', 'There was an error saving this prospect.', 'error', {});
			})
			angular.copy($scope.prospect, $scope.originalProspect);
		};

		$scope.convertToLead = function () {
			if (!angular.equals($scope.prospect, $scope.originalProspect)) {
				$scope.save();
				setTimeout($scope.convert, 1000); // need time for the database to acknowledge/handle the data changes before we convert
			}
			else $scope.convert();
		};

		$scope.convert = function () {
			var data = {
				ProspectDetail: $scope.prospect
			};
			$scope.convertingToLeadPromise = ProspectService.convertToLead(data);
			$scope.convertingToLeadPromise.then(function (output) {
				$location.path('/enquiry-details/' + output.data.LeadId);
				NotificationService.alert('Convert Success', 'This prospect was converted to a lead successfully.', 'success', {});
			}, function (error) {
				NotificationService.alert('Convert Error', 'There was an error converting this prospect to a lead.', 'error', {});
			})
		};

		$scope.goToLead = function () {
			if ($scope.prospect.LeadNumberId > 0)
				$location.path('/enquiry-details/' + $scope.prospect.LeadNumberId);
			else
				NotificationService.alert('Error moving to Enquiry', 'The Enquiry does not exist', 'error', {});
		}


	}])
